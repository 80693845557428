<template>
  <locale-router-link
    v-if="item"
    :to="`products/${item.id}`"
    class="product-block"
    @click.native="gtmHandler"
  >
    <span v-if="!isPdfProduct" class="product-block__header">
      <span v-if="getAttribute('discount')" class="product-block__stock">
        {{ getDiscount(item) }}
        <img
          v-if="getAttribute('best_price')"
          class="product-block__header-icon"
          src="@/assets/img/icons/best_price-icon-black.svg"
          alt=""
        />
      </span>
      <div v-else class="product-block__stock">
        <img
          v-if="getAttribute('best_price')"
          class="product-pdf__header-icon"
          src="@/assets/img/icons/best_price-icon-black.svg"
          alt=""
        />
      </div>

      <span class="product-block__date">{{
        $i18n.locale === "ru"
          ? `до ${getDate(item)} `
          : `${getDate(item)} gacha`
      }}</span>
    </span>
    <!-- //// for pdf  -->
    <div v-else class="product-pdf__header">
      <div
        class="product-pdf__price"
        :class="{
          'best-price': getAttribute('best_price'),
        }"
      >
        <div v-if="getAttribute('discount')" class="product-pdf__stock">
          {{ getDiscount(item) }}
          <img
            v-if="getAttribute('best_price')"
            class="product-pdf__header-icon"
            src="@/assets/img/icons/best_price-icon-black.svg"
            alt=""
          />
        </div>
        <div v-else class="product-pdf__stock">
          <img
            v-if="getAttribute('best_price')"
            class="product-pdf__header-icon"
            src="@/assets/img/icons/best_price-icon-white.svg"
            alt=""
          />
        </div>
        <div
          class="product-pdf__price-wrapper"
          :class="{
            'best-price': getAttribute('best_price'),
          }"
        >
          <span
            v-if="getAttribute('start_price') || getAttribute('best_price')"
            class="product-pdf__price-old"
            >{{ getBestPrice(item) }}</span
          >
          <span
            v-if="getAttribute('final_price')"
            class="product-pdf__price-new"
            >{{ getAttribute("final_price") }}
          </span>
        </div>
      </div>
      <span class="product-block__date">{{
        $i18n.locale === "ru"
          ? `до ${getDate(item)} `
          : `${getDate(item)} gacha`
      }}</span>
    </div>
    <!-- //// for pdf  -->

    <span class="product-block__content" :class="{ pdf: isPdfProduct }">
      <span class="product-block__left">
        <span v-if="!isPdfProduct" class="product-block__img">
          <img v-lazy="{ src: imageUrl, loading: imageLoading }" alt="image" />
        </span>
        <span v-else class="product-block__img">
          <img :src="getImage" alt="image" />
        </span>
        <div
          v-if="
            getAttribute('translation_kaz') &&
            !$isMobile() &&
            $i18n.locale === 'ru' &&
            !isPdfProduct
          "
          class="product-block__translate"
        >
          <span class="product-block__translate-this"> это </span>
          <span class="product-block__translate-word">{{
            getAttribute("translation_kaz")
          }}</span>
        </div>
      </span>

      <span class="product-block__right">
        <!-- <span class="">{{ item.attributes.shopTypes }}</span> -->
        <!-- <span class="">{{ item.attributes.discount_type }}</span> -->

        <span v-if="getAttribute('name')" class="product-block__descr">{{
          getAttribute("name")
        }}</span>
        <span v-if="!isPdfProduct" class="product-block__price-wrapper">
          <span
            v-if="getAttribute('start_price') || getAttribute('best_price')"
            class="product-block__old-price"
            :class="{ 'best-price': getAttribute('best_price') }"
            >{{ getBestPrice(item) }}</span
          >
          <span v-if="getAttribute('final_price')" class="product-block__price"
            >{{ getAttribute("final_price") }}
          </span>
        </span>
      </span>
    </span>
    <div
      v-if="
        $isMobile() &&
        getAttribute('translation_kaz') &&
        $i18n.locale === 'ru' &&
        !isPdfProduct
      "
      class="product-block__translate relative"
    >
      <span class="product-block__translate-this"> это </span>
      <span class="product-block__translate-word">{{
        getAttribute("translation_kaz")
      }}</span>
    </div>
  </locale-router-link>
</template>

<script>
import { eventBus } from "@/helpers";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    fromListName: {
      type: String,
      default: "Каталог скидок", //Товары недели
    },
    catalog: {
      type: Boolean,
      default: false,
    },
    isPdfProduct: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageUrl() {
      if (
        this.item.attributes
          ? this.item.attributes?.image?.data?.length
          : this.item.image?.data?.length
      ) {
        return this.$helpers.getAbsolutePath(
          this.item.attributes
            ? this.item.attributes?.image?.data[0].attributes?.url
            : this.item?.image[0]?.url
        );
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
    getImage() {
      return this.$helpers.getAbsolutePath(
        this.item.attributes
          ? this.item.attributes?.image?.data[0].attributes?.url
          : this.item?.image[0]?.url
      );
    },
    imageLoading() {
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
  },
  // beforeDestroy() {
  //   if (this.fromListName && this.$route.name === "Home") {
  //     console.log("SDKDJFSKDFJSDKFJSDKF");
  //     eventBus.$emit("gtm", {
  //       event: "select_item",
  //       type: "ecommerce",
  //       items: [this.item],
  //       listName: this.fromListName,
  //       category: "Каталог скидок",
  //     });
  //   }
  // },
  methods: {
    getAttribute(value) {
      return this.item.attributes
        ? this.item.attributes[value]
        : this.item[value];
    },
    getDate(item) {
      const dateFromStr = new Date(
        item.attributes ? item.attributes?.action_end : item.action_end
      );
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },
    getDiscount(item) {
      if (item.attributes ? !item.attributes.best_price : !item.best_price) {
        return (
          "-" +
          parseInt(
            (item.attributes ? item.attributes.discount : item.discount) * 100
          ) +
          "%"
        );
      }
    },
    gtmHandler() {
      eventBus.$emit("gtm", {
        event: "select_item",
        type: "ecommerce",
        items: [this.item],
        listName: this.fromListName,
        category: this.catalog ? "Каталог скидок" : "Наивысшая категория",
      });
    },
    getBestPrice(item) {
      if (item.attributes ? item.attributes.best_price : item.best_price) {
        return this.$i18n.locale === "ru"
          ? "Лучшая цена в городе"
          : "Әлеуметтік баға";
      }
      return item.attributes ? item.attributes.start_price : item.start_price;
    },
  },
};
</script>
<style lang="scss">
.product-block {
  &__left {
    position: relative;
  }
  &__translate {
    position: absolute;
    bottom: 0;
    left: 0;
    &.relative {
      position: relative;
    }
    &-this {
      position: relative;
      display: inline-block;
      max-width: max-content;
      padding: 6px 5px 6px 27px;
      background: linear-gradient(226.03deg, #01b6eb 19.32%, #008bc9 70.95%);
      border-radius: 10px;
      font-family: "GT Walsheim Pro Medium";
      font-size: 14px;
      line-height: 14px;
      transform: rotate(-4deg);
      color: #fff;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        background-image: url("~@/assets/img/icons/book-icon.svg");
        width: 14px;
        height: 11px;
      }
    }
    &-word {
      position: relative;
      display: inline-block;
      max-width: max-content;
      padding: 6px 20px 5px 6px;
      background: linear-gradient(226.03deg, #01b6eb 19.32%, #008bc9 70.95%);
      border-radius: 10px;
      font-family: "GT Walsheim Pro Medium";
      font-size: 14px;
      line-height: 14px;
      margin-left: 10px;
      transform: translateY(-5px);
      color: #fff;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        background-image: url("~@/assets/img/icons/ornament.svg");
        width: 11px;
        height: 100%;
      }
    }
  }
}

.product-pdf {
  &__header {
    display: flex;
    justify-content: space-between;
    .product-block__date {
      margin-top: 10px;
      font-size: 20px;
      line-height: 87.69%;
    }
  }
  &__price {
    display: inline-block;
    width: 128px;

    &-wrapper {
      background-color: #fce300;
      padding: 4px;
      &.best-price {
        span {
          color: #f50f64;
        }
        .product-pdf__price-old {
          font-size: 14px;
          line-height: 18px;
          text-decoration: none;
        }
      }
    }
    span {
      color: #2d2f32;
      font-family: "GT Walsheim Pro Bold";
      text-align: right;
    }
    &-old {
      font-size: 18px;
      line-height: 18px;
      text-decoration-line: line-through;
    }
    &-new {
      font-size: 22px;
      line-height: 18px;
    }
  }
  &__stock {
    font-family: "GT Walsheim Pro Bold";
    background-color: #ab0432;
    color: #ffff;
    font-size: 26px;
    line-height: 26px;
    padding: 12px;
    text-align: center;
    img {
      width: 45px;
      height: 18px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 900px) {
  .product-pdf__stock {
    font-size: 22px;
    line-height: 24px;
  }
  .product-pdf__price {
    width: 120px;
  }
  .product-pdf__price-wrapper.best-price .product-pdf__price-old {
    font-size: 12px;
    line-height: 12px;
  }
  .product-pdf__price-new {
    font-size: 20px;
  }
  .product-pdf__price-old {
    font-size: 14px;
    line-height: 14px;
  }
  .product-pdf__header .product-block__date {
    font-size: 18px;
  }
}

@media (max-width: 570px) {
  .product-block__price-wrapper {
    position: relative;
    top: 10px;
  }
  .product-block__right {
    padding-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .product-pdf__price-wrapper.best-price .product-pdf__price-old {
    font-size: 12px;
    line-height: 15px;
  }
  // .product-pdf {
  //   &__header {
  //     display: flex;
  //     justify-content: space-between;
  //     .product-block__date {
  //       margin-top: 10px;
  //     }
  //   }
  //   &__price {
  //     display: inline-block;
  //     &-wrapper {
  //       background-color: #fce300;
  //       padding: 4px;
  //       &.best-price {
  //         span {
  //           color: #f50f64;
  //         }
  //         .product-pdf__price-old {
  //           font-size: 8px;
  //         }
  //       }
  //     }
  //     span {
  //       color: #2d2f32;
  //       font-family: 'GT Walsheim Pro Bold';
  //       text-align: right;
  //     }
  //     &-old {
  //       font-size: 10px;
  //       line-height: 10px;
  //       &.best-price {
  //         font-size: 8px;
  //         line-height: 10px;
  //         color: #f50f64;
  //       }
  //     }
  //     &-new {
  //       font-size: 12px;
  //       line-height: 10px;
  //     }
  //   }
  //   &__stock {
  //     font-family: 'GT Walsheim Pro Bold';
  //     background-color: #f50f64;
  //     color: #ffff;
  //     font-size: 15px;
  //     line-height: 14px;
  //     padding: 5px 17px;
  //     img {
  //       margin: 0 auto;
  //     }
  //   }
  // }
}

@media print {
  .product-pdf__price {
    width: 60px;
  }
  .product-pdf__price.best-price {
    width: 76px;
  }
  .product-pdf__price-wrapper.best-price .product-pdf__price-old {
    font-size: 8px;
    line-height: 10px;
  }
  .product-pdf__stock {
    font-size: 15px;
    line-height: 14px;
    padding: 5px;
    img {
      width: 25px;
      height: 10px;
    }
  }
  .product-pdf__price-new {
    font-size: 18px;
  }
  .product-pdf__price-old {
    font-size: 10px;
    line-height: 10px;
  }
  .product-pdf__price-new {
    font-size: 12px;
    line-height: 10px;
  }
}
</style>
