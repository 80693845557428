var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('locale-router-link',{staticClass:"product-block",attrs:{"to":("products/" + (_vm.item.id))},nativeOn:{"click":function($event){return _vm.gtmHandler.apply(null, arguments)}}},[(!_vm.isPdfProduct)?_c('span',{staticClass:"product-block__header"},[(_vm.getAttribute('discount'))?_c('span',{staticClass:"product-block__stock"},[_vm._v(" "+_vm._s(_vm.getDiscount(_vm.item))+" "),(_vm.getAttribute('best_price'))?_c('img',{staticClass:"product-block__header-icon",attrs:{"src":require("@/assets/img/icons/best_price-icon-black.svg"),"alt":""}}):_vm._e()]):_c('div',{staticClass:"product-block__stock"},[(_vm.getAttribute('best_price'))?_c('img',{staticClass:"product-pdf__header-icon",attrs:{"src":require("@/assets/img/icons/best_price-icon-black.svg"),"alt":""}}):_vm._e()]),_c('span',{staticClass:"product-block__date"},[_vm._v(_vm._s(_vm.$i18n.locale === "ru" ? ("до " + (_vm.getDate(_vm.item)) + " ") : ((_vm.getDate(_vm.item)) + " gacha")))])]):_c('div',{staticClass:"product-pdf__header"},[_c('div',{staticClass:"product-pdf__price",class:{
        'best-price': _vm.getAttribute('best_price'),
      }},[(_vm.getAttribute('discount'))?_c('div',{staticClass:"product-pdf__stock"},[_vm._v(" "+_vm._s(_vm.getDiscount(_vm.item))+" "),(_vm.getAttribute('best_price'))?_c('img',{staticClass:"product-pdf__header-icon",attrs:{"src":require("@/assets/img/icons/best_price-icon-black.svg"),"alt":""}}):_vm._e()]):_c('div',{staticClass:"product-pdf__stock"},[(_vm.getAttribute('best_price'))?_c('img',{staticClass:"product-pdf__header-icon",attrs:{"src":require("@/assets/img/icons/best_price-icon-white.svg"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"product-pdf__price-wrapper",class:{
          'best-price': _vm.getAttribute('best_price'),
        }},[(_vm.getAttribute('start_price') || _vm.getAttribute('best_price'))?_c('span',{staticClass:"product-pdf__price-old"},[_vm._v(_vm._s(_vm.getBestPrice(_vm.item)))]):_vm._e(),(_vm.getAttribute('final_price'))?_c('span',{staticClass:"product-pdf__price-new"},[_vm._v(_vm._s(_vm.getAttribute("final_price"))+" ")]):_vm._e()])]),_c('span',{staticClass:"product-block__date"},[_vm._v(_vm._s(_vm.$i18n.locale === "ru" ? ("до " + (_vm.getDate(_vm.item)) + " ") : ((_vm.getDate(_vm.item)) + " gacha")))])]),_c('span',{staticClass:"product-block__content",class:{ pdf: _vm.isPdfProduct }},[_c('span',{staticClass:"product-block__left"},[(!_vm.isPdfProduct)?_c('span',{staticClass:"product-block__img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({ src: _vm.imageUrl, loading: _vm.imageLoading }),expression:"{ src: imageUrl, loading: imageLoading }"}],attrs:{"alt":"image"}})]):_c('span',{staticClass:"product-block__img"},[_c('img',{attrs:{"src":_vm.getImage,"alt":"image"}})]),(
          _vm.getAttribute('translation_kaz') &&
          !_vm.$isMobile() &&
          _vm.$i18n.locale === 'ru' &&
          !_vm.isPdfProduct
        )?_c('div',{staticClass:"product-block__translate"},[_c('span',{staticClass:"product-block__translate-this"},[_vm._v(" это ")]),_c('span',{staticClass:"product-block__translate-word"},[_vm._v(_vm._s(_vm.getAttribute("translation_kaz")))])]):_vm._e()]),_c('span',{staticClass:"product-block__right"},[(_vm.getAttribute('name'))?_c('span',{staticClass:"product-block__descr"},[_vm._v(_vm._s(_vm.getAttribute("name")))]):_vm._e(),(!_vm.isPdfProduct)?_c('span',{staticClass:"product-block__price-wrapper"},[(_vm.getAttribute('start_price') || _vm.getAttribute('best_price'))?_c('span',{staticClass:"product-block__old-price",class:{ 'best-price': _vm.getAttribute('best_price') }},[_vm._v(_vm._s(_vm.getBestPrice(_vm.item)))]):_vm._e(),(_vm.getAttribute('final_price'))?_c('span',{staticClass:"product-block__price"},[_vm._v(_vm._s(_vm.getAttribute("final_price"))+" ")]):_vm._e()]):_vm._e()])]),(
      _vm.$isMobile() &&
      _vm.getAttribute('translation_kaz') &&
      _vm.$i18n.locale === 'ru' &&
      !_vm.isPdfProduct
    )?_c('div',{staticClass:"product-block__translate relative"},[_c('span',{staticClass:"product-block__translate-this"},[_vm._v(" это ")]),_c('span',{staticClass:"product-block__translate-word"},[_vm._v(_vm._s(_vm.getAttribute("translation_kaz")))])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }